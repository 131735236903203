<template>
    <b-container fluid >
    <b-row>
      <b-col md="12" class="allCardList">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa fa-people-line" aria-hidden="true" style="font-size: 21px; margin-right: 3px; color: var(--iq-primary);"></i>
              Clubs
              <span class="btn_in_header">
                <span class="pull-right" v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'">
                  <b-button variant="primary" @click="clubAdd()">Add (+)</b-button>
                </span>
              </span>
            </h4>
          </template>
        </iq-card>
        <iq-card>
          <div class="col-md-12 p-0">
            <iq-card class="iq-card">
              <div class="iq-card-body p-0 showFlexListDiv">
                <div class="user-tabing showFlexList">
                  <tab-nav :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items showHoriZon w-100" style="padding: 0px !important;">
                    <div  class="flex-fill p-0" @click="switchTabs('allClubs')">
                      <tab-nav-items class="flex-fill p-0" :active="allClubActive" id="pills-all-clubs-tab" href="#profile-all-clubs" ariaControls="pills-all-clubs" role="tab" :ariaSelected="allClubActive" title="All Clubs" />
                    </div>
                    <div  class="flex-fill p-0" @click="switchTabs('followedClubs')" >
                      <tab-nav-items  class="flex-fill p-0" :active="followingClubActive" id="pills-following-clubs-tab"  href="#profile-following-clubs"  ariaControls="pills-following-clubs" role="tab" :ariaSelected="followingClubActive" title="Followed Clubs" />
                    </div>
                  </tab-nav>
                </div>
              </div>
            </iq-card>
          </div>
        </iq-card>
          <b-col sm="12" class="p-0">
            <div class="tab-content">
              <tab-content-item :active="true" id="profile-all-clubs" aria-labelled-by="pills-all-clubs-tab">
                <div class="rounded p-2 bg-white" >
                  <b-form-input v-model="vmClubSearch" placeholder="Search" @input="clubList(vmClubSearch)">
                  </b-form-input>
                </div>
                <div v-if="Object.values(clubListObj).length >0">
                  <b-row class="p-2" >
                    <div v-for="(club,index) in clubListObj" :key="index" class="mt-5 col-12 col-sm-6 col-md-4 col-lg-3">
                      <iq-card className="iq-card-block iq-card-stretch iq-card-height side_border">
                        <template v-slot:body>
                          <div class="iq-badges text-left" @click="goToClubView(club)" style="cursor: pointer;">
                            <div class="badges-icon">
                              <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" v-if="clubImgObj && clubImgObj[club.club_id] && clubImgObj[club.club_id]['profile_pic']">
                                <img :src="clubImgObj[club.club_id]['profile_pic']['image_thumbnail']" class="avatar-80 rounded" alt="Club">
                              </b-avatar>
                              <b-avatar v-else class="avatar-80 rounded" :text="getFirstLetterOfAString(club.club_name)" style="border-radius: 100%!important;">
                              </b-avatar>
                              <div class="status" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') && club.is_gide_plus != 1" @click="clubPublishUnpublish($event, club)">
                                <b-badge v-if="club.deleted === 0" class="pointer float-right " variant="success mr-1 mb-1" size="sm">
                                  Published
                                </b-badge>
                                <b-badge v-if="club.deleted === 2" class="pointer ml-1" variant="warning mr-1 mb-1" size="sm">
                                  Unpublished
                                </b-badge>
                                <b-badge v-if="club.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm">
                                  Deleted
                                </b-badge>
                              </div>
                              <div class="status1" v-if="(userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111') && club.is_gide_plus == 1" @click="clubPublishUnpublish($event, club)">
                                <b-badge v-if="club.deleted === 0" class="pointer float-right " variant="success mr-1 mb-1" size="sm">
                                  Published
                                </b-badge>
                                <b-badge v-if="club.deleted === 2" class="pointer ml-1" variant="warning mr-1 mb-1" size="sm">
                                  Unpublished
                                </b-badge>
                                <b-badge v-if="club.deleted === 1" class="pointer ml-1" variant="primary mr-1 mb-1" size="sm">
                                  Deleted
                                </b-badge>
                              </div>
                              <br>
                              <div class="gidePlus">
                                <b-badge  v-if="club.is_gide_plus === 1" class="border font-weight-normal" variant="primary" style="font-weight: bold !important; display: block;">
                                  Gide Plus
                                </b-badge>
                                <span v-else class="font-weight-normal">
                                  &nbsp;
                                </span>
                              </div>
                            </div>
                            <h5 class="mb-2 title twoLineOnly" :title="club.club_name">
                              {{club.club_name}}
                            </h5>
                            <div class="">
                              <span class="mr-2 font-weight-normal" variant="primary">
                                By {{club.org_name ? club.org_name : "Gide"}}
                              </span><br>
                              <div class="float-right">
                                <button style="all:unset; font-weight: 500; cursor: pointer; margin-right: 15px;" v-if="clubFollowObj[club.club_id]" @click="followClub($event, club.club_id, clubFollowObj[club.club_id].followed_by_curr_user)">
                                  <span v-if="clubFollowObj[club.club_id].followed_by_curr_user===0" class="text-primary">Follow</span>
                                  <span v-else class="text-secondary">Unfollow</span>
                                </button>
                                <b-button class="float-right" variant=" iq-bg-danger" size="sm" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                                    <i class="ri-delete-bin-line m-0" @click="showClubDeleteDialog($event, club)"></i>
                                </b-button>
                              </div>
                            </div>
                          </div>
                        </template>
                      </iq-card>
                    </div>
                  </b-row>
                  <div class="d-flex justify-content-center flex-row flex-fill mb-4" v-if="allClubActive && showLoadMoreBtn && currentPage !== totalPage">
                    <button class="btn btn-primary" @click="loadMore('allClubs')">
                      Load More
                    </button>
                  </div>
                </div>
                <div v-else class="p-2 text-center flex flex-fill justify-content-center">
                  No Data Found
                </div>
              </tab-content-item>
              <tab-content-item :active="false" id="profile-following-clubs" aria-labelled-by="pills-following-clubs-tab">
                <b-row class="p-2">
                  <div v-for="(club,index) in clubFollowingList" :key="index" class="mt-5 col-12 col-sm-6 col-md-4 col-lg-3">
                    <iq-card className="iq-card-block iq-card-stretch iq-card-height side_border">
                      <template v-slot:body>
                        <div class="iq-badges text-left" @click="goToClubView(club.club_id)" style="cursor: pointer;">
                          <div class="badges-icon">
                            <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" v-if="clubImgObj && clubImgObj[club.club_id] && clubImgObj[club.club_id]['profile_pic']">
                              <img :src="clubImgObj[club.club_id]['profile_pic']['image_thumbnail']" class="avatar-80 rounded" alt="Club">
                            </b-avatar>
                            <b-avatar v-else class="avatar-80 rounded" :text="getFirstLetterOfAString(club.club_name)" style="border-radius: 100%!important;">
                            </b-avatar>
                            <div class="gidePlus">
                              <b-badge  v-if="club.is_gide_plus === 1" class="border font-weight-normal" variant="primary" style="font-weight: bold !important;">
                                Gide Plus
                              </b-badge>
                              <span v-else class="font-weight-normal">
                                &nbsp;
                              </span>
                            </div>
                          </div>
                          <h5 class="mb-2 title twoLineOnly" :title="club.club_name">
                            {{club.club_name}}
                          </h5>
                          <div class="d-flex justify-content-between">
                            <span class="text-uppercase">by {{club.org_name ? club.org_name : club.user_name}}</span>
                            <button style="all:unset; font-weight: 500; cursor: pointer;" v-if="clubFollowObj[club.club_id]" @click="followClub($event, club.club_id, clubFollowObj[club.club_id].followed_by_curr_user)">
                              <span v-if="clubFollowObj[club.club_id].followed_by_curr_user===0" class="text-primary">Follow</span>
                              <span v-else class="text-secondary">Unfollow</span>
                            </button>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </div>
                </b-row>
                <b-modal v-model="showModelClubDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
                  <p>
                    {{cvDelMessage}}
                  </p>
                  <template #modal-footer="">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button size="sm" @click="showModelClubDelete = false">
                      {{cvbtnModalCancel}}
                    </b-button>
                    <b-button size="sm" variant="primary" @click="clubDelete()">
                      {{cvbtnModalDelOk}}
                    </b-button>
                  </template>
                </b-modal>
                <div class="d-flex justify-content-center flex-row flex-fill mb-4" v-if="followingClubActive && (allClubFollowingCount != (Object.keys(clubFollowingList).length))">
                  <button class="btn btn-primary" @click="loadMore('followedClubs')">
                    Load More
                  </button>
                </div>
              </tab-content-item>
            </div>
          </b-col>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
    <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showUpgradeAlertBox=false" @emitConfirmAlertModel="goToClubPlanList()"/>
  </b-container>

</template>
<style scoped lang="scss">
.showFlexListDiv .showFlexList .profile-feed-items{
  min-width: 100%
}
.gidePlus{
  position: absolute;
  right: 0px;
  top: 55px;
  margin: 0px;
  span{
    float: right;
  }
}
.badges-icon{
  position: relative;
}
.status{
  position: absolute;
  bottom: 0;
  right: 0;
}
.status1{
  position: absolute;
  bottom: -25px;
  right: -6px;
}
</style>
<script>
import { socialvue } from "../../../../config/pluginInit"
import { Clubs } from "../../../../FackApi/api/club"
import { Follows } from "../../../../FackApi/api/follow"
import moment from "moment"
import ApiResponse from "../../../../Utils/apiResponse.js"
import Utility from "../../../../Utils/utility"
import AlertBox from "../../../../components/AlertBox.vue"
export default {
  name: "ClubList",
  components: {
    AlertBox
  },
  data () {
    return {
      soicalInfo: [
        {
          name: "Followed Clubs"
        },
        {
          name: "All Clubs"
        }
      ],
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are You Sure?",
      cvbtnModalCancel: "Cancel",
      delObj: {},
      showModelClubDelete: false,
      clubListObj: {},
      clubImgObj: {},
      clubFollowObj: {},
      clubFollowingList: [],
      allClubActive: true,
      followingClubActive: false,
      allClubFollowingCount: 0,
      allClubCount: 0,
      currentPage: 1,
      totalPage: "",
      showLoadMoreBtn: false,
      vmClubSearch: "",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Club Follow Response",
      vmFollowData: Object.assign({}, this.initFollowData()),
      vmTotalFollowedCount: 0,
      vmBannerImage: Utility.getBgImageForLetter(this, "CLUB"),
      showUpgradeAlertBox: false,
      alertDesc: "This is club is available only for Gide Plus Members. Please upgrade your membership to Gide Plus &amp; get access to this club.",
      alertTitle: "Clubs",
      okText: "Upgrade",
      cancelText: "Cancel"
    }
  },
  mounted () {
    this.loadRequiredData()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    myPlan () {
      return this.$store.getters["Paygw/myPlanState"]
    }
  },
  methods: {
    /**
     * initFollowData
     */
    initFollowData () {
      return {
        module_id: "",
        module_name: "",
        follow_status: 0
      }
    },
    /**
     * loadRequiredData
     */
    loadRequiredData () {
      socialvue.index()
      this.clubList()
      this.clubFollowedList()
    },
    /**
     * getFormattedDate
     */
    getFormattedDate (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * goToClubPlanList
     */
    goToClubPlanList () {
      this.$router.push("/subscription_plans")
    },
    /**
     * goToClubView
     */
    goToClubView (club) {
      if (club.is_gide_plus === 0) {
        this.$router.push("/club/" + club.club_id)
      }
      else {
        if ((this.myPlan && this.myPlan.subs_id) || this.userData.user_role === "USERROLE11111" || this.userData.user_role === "USERROLE11118") {
          this.$router.push("/club/" + club.club_id)
        }
        else {
          this.alertTitle = club.club_name
          this.alertDesc = club.club_desc + "\r\n" + this.alertDesc
          this.showUpgradeAlertBox = true
        }
      }
    },
    /**
     * showClubDeleteDialog
     */
    showClubDeleteDialog (event, item) {
      event.stopPropagation()
      try {
        this.delObj = item
        this.showModelClubDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showClubDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * Publish/UnPublish club
     */
    async clubPublishUnpublish (event, club) {
      event.stopPropagation()
      let payload = {
        club_id: club.club_id,
        deleted: null
      }

      if (club.deleted === 0) {
        // club is published then unpublish
        payload.deleted = 2
      }
      else if (club.deleted === 2) {
        payload.deleted = 0
      }
      else {
        return
      }

      let clubResp = await Clubs.club_publish_unpublish(this, payload)
      if (clubResp.resp_status === true) {
        club.deleted = payload.deleted
      }
      else {
        ApiResponse.responseMessageDisplay(this, clubResp)
      }
    },
    /**
     * clubAdd
     */
    clubAdd () {
      this.$router.push(`/club_create/${this.userData.user_id}`)
    },
    /*
    * ClubList
    **/
    async clubList (searchString = "") {
      try {
        let filter = { user_role: this.userData.user_role }
        if (searchString && searchString.length) {
          filter = {
            where: {
              "OR":
              [
                {
                  club_name: searchString
                },
                {
                  club_desc: searchString
                }
              ]
            }
          }
        }

        let clubListResp = await Clubs.clubList(this, this.currentPage, filter)

        if (clubListResp && clubListResp.resp_status) {
          if (clubListResp.resp_data.currentPage) {
            this.currentPage = parseInt(clubListResp.resp_data.currentPage)
          }

          if (clubListResp.resp_data.totalPage) {
            this.totalPage = parseInt(clubListResp.resp_data.totalPage)
          }

          if (this.currentPage && this.totalPage && this.currentPage >= this.totalPage) {
            this.showLoadMoreBtn = false
          }
          else {
            this.showLoadMoreBtn = true
          }

          this.allClubCount = clubListResp.resp_data.totalCount
          if (this.currentPage === 1) {
            this.clubListObj = { ...clubListResp.resp_data.data }
          }
          else {
            this.clubListObj = { ...this.clubListObj, ...clubListResp.resp_data.data }
          }

          this.clubImgObj = { ...this.clubImgObj, ...clubListResp.club_images }
          this.clubFollowObj = { ...this.clubFollowObj, ...clubListResp.club_follow }
        }
        else {
          this.clubListObj = {}
        }
      }
      catch (err) {
        console.error("Exception occurred at clubList() and Exception:", err.message)
      }
    },
    /**
     * clubDelete
     */
    async clubDelete () {
      try {
        let clubDelResp = await Clubs.clubDelete(this, this.delObj.club_id)
        await ApiResponse.responseMessageDisplay(this, clubDelResp)
        if (clubDelResp && !clubDelResp) {
          this.showModelClubDelete = false
          return false
        }
        this.delObj.deleted = 1
        this.showModelClubDelete = false
      }
      catch (err) {
        console.error("Exception occurred at clubDelete() and Exception:", err.message)
      }
    },
    /*
    * ClubListFollowed
    **/
    async clubFollowedList () {
      try {
        let clubFollowedListResp = await Clubs.clubFollowedList(this, this.currentPage)
        if (clubFollowedListResp && clubFollowedListResp.resp_status) {
          if (clubFollowedListResp.resp_data.currentPage) {
            this.currentPage = parseInt(clubFollowedListResp.resp_data.currentPage)
          }

          if (clubFollowedListResp.resp_data.totalCount) {
            this.allClubFollowingCount = parseInt(clubFollowedListResp.resp_data.totalCount)
          }

          if (this.currentPage && this.totalPage && this.currentPage >= this.totalPage) {
            this.showLoadMoreBtn = false
          }
          else {
            this.showLoadMoreBtn = true
          }

          this.vmTotalFollowedCount = clubFollowedListResp.resp_data.totalCount
          this.clubFollowingList = { ...this.clubFollowingList, ...clubFollowedListResp.resp_data.data }
          this.clubImgObj = { ...this.clubImgObj, ...clubFollowedListResp.club_images }
          this.clubFollowObj = { ...this.clubFollowObj, ...clubFollowedListResp.club_follow }
        }
      }
      catch (err) {
        console.error("Exception occurred at clubFollowedListResp() and Exception:", err.message)
      }
    },
    /**
     *  followclub
     */
    async followClub (event, moduleId, followStatus) {
      event.stopPropagation()
      try {
        this.vmFollowData.module_id = moduleId
        this.vmFollowData.module_name = "CLUB"
        this.vmFollowData.follow_status = followStatus ? 0 : 1
        let followResp = await Follows.followAdd(this, this.vmFollowData)

        await ApiResponse.responseMessageDisplay(this, followResp)
        if (followResp && !followResp.resp_status) {
          return
        }
        else {
          if (followStatus) {
            this.$delete(this.clubFollowingList, moduleId, this.clubListObj[moduleId])
            this.vmTotalFollowedCount--
          }
          else {
            this.$set(this.clubFollowingList, moduleId, this.clubListObj[moduleId])
            this.vmTotalFollowedCount++
          }

          this.clubFollowObj[moduleId].followed_by_curr_user = this.vmFollowData.follow_status
          this.vmFollowData = Object.assign({}, this.initFollowData())
        }
      }
      catch (err) {
        console.error("Exception occured at followClub() and Exception", err.message)
      }
      finally {
        this.$store.dispatch("OpenMesh/setOpenMesh", true)
      }
    },
    /**
     * switchTabs
     */
    async switchTabs (currentTab) {
      try {
        if (this.allClubActive && currentTab === "allClubs") {
          return
        }
        else if (this.followingClubActive && currentTab === "followedClubs") {
          return
        }
        else {
          let currPage = 1
          this.currentPage = parseInt(currPage)
          this.totalPage = ""
          this.showLoadMoreBtn = false
          if (currentTab === "allClubs") {
            this.allClubActive = true
            this.followingClubActive = false
            await this.clubList()
          }
          else if (currentTab === "followedClubs") {
            this.allClubActive = false
            this.followingClubActive = true
            await this.clubFollowedList()
          }
        }
      }
      catch (err) {
        console.error("Exception occured at switchTabs() and Exception", err.message)
      }
    },
    /**
     * loadMore()
     */
    loadMore (currentTab) {
      try {
        if (this.currentPage === this.totalPage) {
          this.showLoadMoreBtn = false
          return
        }
        else {
          let currPage = this.currentPage
          this.currentPage = parseInt(currPage) + 1
          if (this.vmClubSearch && currentTab === "allClubs") {
            this.clubList(this.vmClubSearch)
          }
          else if (currentTab === "allClubs") {
            this.clubList()
          }
          else if (currentTab === "followedClubs") {
            this.clubFollowedList()
          }
        }
      }
      catch (err) {
        console.error("Exception occured at loadMore() and Exception", err.message)
      }
    }
  }
}
</script>
