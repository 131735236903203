/*eslint-disable */
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"

let Clubs = {
  /**
   * clubList
   */
  async clubListAdmin (context, currentPage='', filter = {}, searchString=null) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
  
      if (currentPage) {
        post_data.append('currentPage', currentPage)
      }
  
      if (Object.keys(filter).length > 0) {
        post_data.append('filter', JSON.stringify(filter))
      }
  
      return await request.curl(context, "club_list_admin", post_data)
     .then(async response => {
       return response
     });
    }
    catch(err){
      console.error('Exception occurred at club_list_admin() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },
  /**
   * clubList
   */
  async clubList (context, currentPage='', filter = {}, searchString=null) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
      if (currentPage) {
        post_data.append('currentPage', currentPage)
      }
      if (Object.keys(filter).length > 0) {
        post_data.append('filter', JSON.stringify(filter))
      }
      return await request.curl(context, "club_list", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at clubList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * clubListFollowed
   */
   async clubFollowedList (context, currentPage='', filter = {}) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
      if (currentPage) {
        post_data.append('currentPage', currentPage)
      }
      if (Object.keys(filter).length > 0) {
        post_data.append('filter', JSON.stringify(filter))
      }
      return await request.curl(context, "club_list_followed", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at clubFollowedList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * clubView
   */
  async clubView (context, clubId) {
    try {
      let post_data = new FormData();
      post_data.append('club_id', clubId);
      return await request.curl(context, "club_view", post_data)
        .then(async response => {
          return response;
        });
    }
    catch (err) {
      console.error("Exception occurred at clubView() and Exception:",err.message)
    }

  },

  /**
   * clubAdd
   */
  async clubAdd (context, clubObj) {
    try{
      let post_data = new FormData();

      for (let key in clubObj) {
        if (key == 'images') {
          post_data.append(key, JSON.stringify(clubObj[key]));
        }
        post_data.append(key, clubObj[key]);
      }

      return await request.curl(context, "club_add", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at clubAdd() and Exception:',err.message)
    }
  },

  /**
   * clubEdit
   */
  async clubEdit (context, clubObj) {
    try{
      let post_data = new FormData();

      for (let key in clubObj) {
        post_data.append(key, clubObj[key]);
      }

      return await request.curl(context, "club_edit", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at clubEdit() and Exception:',err.message)
    }
  },

  /**
   * clubDelete
   */
  async clubDelete (context, clubId) {
    try{
      let post_data = new FormData();

      post_data.append('club_id', clubId);

      return await request.curl(context, "club_delete", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at clubDelete() and Exception:',err.message)
    }
  },
  /**
   * club_publish_unpublish
   */
  async club_publish_unpublish (context, club) {
    try{
      let post_data = new FormData();
      post_data.append('club_id', club.club_id);
      post_data.append('deleted',club.deleted);

      return await request.curl(context, "club_publish_unpublish", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at club_publish_unpublish() and Exception:',err.message)
    }
  },
}

export {
  Clubs
}
